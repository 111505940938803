import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CallApi } from 'utils/Request';

export default function () {

    const [testComplete, setTestComplete] = useState(false)
    const [apiWorking, setApiWorking] = useState(false)
    useEffect(() => {
        CallApi({
            url: '/test',
        }).then(() => {
            setApiWorking(true)
        }).catch((e) => {
        }).finally(() => {
            setTestComplete(true)
        })
    }, [])
    if (testComplete) {
        if (apiWorking) {
            return <Box>
                <Outlet />
            </Box>
        } else {
            return <Box>
                <Typography color='error'>Api not working, please try on chrome browser.</Typography>
            </Box>
        }
    } else {
        return <Typography>...testing api</Typography>
    }
}
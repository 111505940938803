import { Alert, Box, Container, Fab, Typography } from '@mui/material';
import { Part1Data, Part2Data } from 'config/constants';
import { ProfileType, SurveyResultType, SymptomAnswerType } from 'model/symptom';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { getAge } from 'utils/Common';
import { CallApi } from 'utils/Request';
import { getSectionScore } from 'utils/SurveyUtil';


export default function () {
    const params = useParams()
    const [surveyData, setSurveyData] = useState<SurveyResultType | null>(null)
    const [callEnded, setCallEnded] = useState(false)
    const ALL_SECTIONS = Part1Data.sections.concat(Part2Data.sections)

    useEffect(() => {
        if (params.checkout_session_id) {
            CallApi({
                url: 'survey/get/' + params.checkout_session_id,
                method: 'get'
            }).then((res) => {
                setSurveyData(res)
            }).finally(()=>{
                setCallEnded(true)
            })
        }
    }, [params])

    return <Container maxWidth='lg'>
        {callEnded && !surveyData && <Typography color='error'>There is no record in database.</Typography>}
        {surveyData && <Box py={6}>
            <Box>
                <Typography variant='h3' my={1} color='primary'>{surveyData?.name}'s Assessment Answers</Typography>
                <Box sx={{ backgroundColor: 'lightyellow', padding: 1, borderRadius: 2 }}>
                    <Box>
                        <Typography>User Name: {surveyData?.name}</Typography>
                    </Box>
                    <Box>
                        <Typography>Date: {surveyData?.submit_date}</Typography>
                    </Box>
                    <Box>
                        <Typography>Age: {getAge(surveyData?.birth_date)}</Typography>
                    </Box>
                    <Box>
                        <Typography>Biological Gender: {surveyData?.birth_gender}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                {
                    ALL_SECTIONS.map((section, key) => {
                        const sectionScore = getSectionScore(section.section_name, surveyData.symptom_answers)
                        return <Box key={key}>
                            <Box my={2}>
                                <Typography variant='h4' textAlign='center' color='primary.main'>{section.section_label} (Total: {sectionScore?.score})</Typography>
                                <Typography variant='body2' >{section.description}</Typography>
                            </Box>
                            <Box>
                                {
                                    section.symptoms.map((symptom, key) => {
                                        const symptomAnswer = surveyData.symptom_answers.filter(s => s.id == symptom.id)[0]

                                        return <Box mb={1} key={key}>
                                            <Alert icon={false}>
                                                <Typography>{!section.hide_index ? symptom.id + '.' : ''} {symptom.label}</Typography>
                                                {
                                                    symptom.note && <Typography variant='caption' color='error.main'>{symptom.note}</Typography>
                                                }
                                                <Box m={2}>
                                                    {
                                                        symptom.options.map((option, okey) => {
                                                            return (
                                                                <Fab color={symptomAnswer?.value == option ? 'primary' : undefined} aria-label="add" key={okey}
                                                                    sx={{ mr: 1 }} size='small'
                                                                >
                                                                    <Typography>{option}</Typography>
                                                                </Fab>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Alert>
                                        </Box>
                                    })
                                }
                            </Box>
                        </Box>

                    })
                }
            </Box>
        </Box>}
    </Container>
}
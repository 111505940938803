import * as React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


export default function AssessCompleteModal({
    onConfirm
}: {
    onConfirm: () => void
}) {

    return (
        <Dialog
            open={true}
            onClose={onConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            <DialogTitle sx={{ textAlign: 'center' }}>

                <Player
                    src='https://assets4.lottiefiles.com/packages/lf20_xldzoar8.json'
                    autoplay
                    style={{ height: '150px', width: '150px' }}
                    loop
                />
                <Typography variant='h4' color='warning.light' ml={2} mt={2} fontFamily='cursive'>
                    Congratulations!
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography variant='h6' color='success.main' fontFamily={"serif"} textAlign='center'>
                        You've completed the SexyStrongLife Optimal Health Assessment. Your Optimal Health Priority Report is being generated.
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} variant='contained'>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import React, { useState, useEffect } from 'react'
import { Box, Container, Typography, Button, Alert, Grid } from '@mui/material';
import { SectionType, SymptomType } from 'model/symptom';
import Fab from '@mui/material/Fab';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { updateSymptomAnswers } from 'redux/reducers/formdata';
import SupportiveModal from './SupportiveModal';
import { RootState } from 'redux/store';

function Symptom({
    symptom,
    showIndex
}: {
    symptom: SymptomType,
    showIndex: boolean
}) {
    const formdata = useSelector((state: RootState) => state.formdata)

    const [selectedOption, setSelectedOption] = useState<number | undefined>(undefined)
    useEffect(() => {
        const symptomAnswers = formdata.SYMPTOM_ANSWERS
        const answer = symptomAnswers.filter(s => s.id == symptom.id)[0]
        if (answer) {
            setSelectedOption(answer.value)
        }
    }, [])

    const dispatch = useDispatch()

    const updateSelectedOption = (option: number) => {
        setSelectedOption(option)
        dispatch(updateSymptomAnswers({
            id: symptom.id,
            value: option
        }))
    }
    return <Box>
        <Alert icon={false}>
            <Typography>{showIndex ? symptom.id + '.' : ''} {symptom.label}</Typography>
            {
                symptom.note && <Typography variant='caption' color='error.main'>{symptom.note}</Typography>
            }
            <Box m={2}>
                {
                    symptom.options.map((option, okey) => {
                        return (
                            <Fab color={selectedOption == option ? 'primary' : undefined} aria-label="add" key={okey}
                                sx={{ mr: 1 }} size='small'
                                onClick={() => updateSelectedOption(option)}
                            >
                                <Typography>{option}</Typography>
                            </Fab>
                        )
                    })
                }
            </Box>
        </Alert>
    </Box>
}
export default function ({
    section,
    onSkipSection
}: {
    section: SectionType,
    onSkipSection?: () => void
}) {
    const [animateShow, setAnimateShow] = useState(false)
    useEffect(() => {
        setAnimateShow(true)
    }, [])

    return <Slide in={animateShow} direction="left">
        <Box>
            <Box my={2}>
                <Typography variant='h4' textAlign='center' color='primary.main'>{section.section_label}</Typography>
                <Typography variant='body2' >{section.description}</Typography>
                {
                    section.skippable && <>
                        <Typography variant='body2' >
                            {section.skip_text}
                            <Button size='small' onClick={onSkipSection}>
                                here
                            </Button>
                        </Typography>
                    </>
                }

            </Box>
            <Box>
                {
                    section.symptoms.map((symptom, key) => {
                        return <Box mb={1} key={key}>
                            <Symptom symptom={symptom} showIndex={!section.hide_index} />
                        </Box>
                    })
                }
            </Box>
        </Box>
    </Slide >
}
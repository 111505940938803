import { Box, Button, Container, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { paymentCompleted } from 'redux/reducers/appdata';
import { RootState } from 'redux/store';
import moment from 'moment'

const ADMIN_LIST = [
    {
        name: 'sheri',
        password: 'levy'
    }
]
export default function () {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const formdata = useSelector((state: RootState) => state.formdata)
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    // formdata
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')

    const onSubmit = () => {
        const exist = ADMIN_LIST.filter((admin) => {
            if (admin.name == name && admin.password == password) {
                return true
            } else {
                return false
            }
        })[0]
        if (exist) {
            onSuccessLogin(exist)
        } else {
            setErrorMsg('Invalid authentication!')
        }
    }
    const onSuccessLogin = (admin: {
        name: string, password: string
    }) => {
        const dayStr = moment().format('YYYY_MM_DD')
        const timestampStr = moment().valueOf()
        const session_id = `admin_${admin.name}_${dayStr}_${timestampStr}`
        dispatch(
            paymentCompleted(session_id)
        )
        setTimeout(() => {
            navigate('/survey/profile')
        }, 0);
    }

    return <Box>
        {errorMsg && <Alert severity="error" sx={{ position: 'fixed', left: 0, top: 0, right: 0 }}>{errorMsg}</Alert>}

        <Box my={6}>
            <Typography variant='h4' sx={{ color: 'info.main', textAlign: 'center' }}>
                Admin
            </Typography>
        </Box>
        <Container maxWidth='sm'>
            <Box mb={4}>
                <TextField variant='filled' label="Name:" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
            </Box>
            <Box mb={4}>
                <TextField variant='filled' label="Password:" fullWidth value={password} onChange={(e) => setPassword(e.target.value)}
                    type='password'
                />
            </Box>
            <Box my={6}>
                <Button variant='contained' fullWidth onClick={onSubmit}>Next</Button>
            </Box>
        </Container>
    </Box>

}
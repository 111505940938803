import axios from "axios"
import { API_BASE_URL } from "config/env";
import { store } from "redux/store";
axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = false;
export const CallApi = (params: any, options = { showError: true }): Promise<any> => {
    // const state = store.getState()
    // const user = state.user
    let newparams = params
    // if (isLogin()) {
    //     const header = "Bearer " + state.user.TOKEN;
    //     newparams.headers = { Authorization: header }
    // }
    return new Promise((resolve, reject) => {
        axios(newparams).then((res: any) => {
            resolve(res.data)
        }).catch((error: any) => {
            reject(error)
        })
    })
}
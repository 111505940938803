import * as React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Lottie from 'react-lottie-player'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ANIMATE_LINKS = [
    'https://assets1.lottiefiles.com/packages/lf20_myejiggj.json',
    'https://assets9.lottiefiles.com/packages/lf20_fnjH1K.json',
    'https://assets1.lottiefiles.com/packages/lf20_myejiggj.json',
    'https://assets4.lottiefiles.com/packages/lf20_6FP3kYFVYd.json',
    'https://assets5.lottiefiles.com/packages/lf20_8LsVR1wgsM.json',
    'https://assets9.lottiefiles.com/packages/lf20_pkzscvhb.json',
    'https://assets9.lottiefiles.com/packages/lf20_pkzscvhb.json',
    'https://assets7.lottiefiles.com/packages/lf20_oc9peor8.json',
    'https://assets1.lottiefiles.com/packages/lf20_sfiiilbf.json',
    'https://assets1.lottiefiles.com/packages/lf20_sytxvxgf.json',
    'https://assets1.lottiefiles.com/packages/lf20_cq4kbg52.json',
    'https://assets6.lottiefiles.com/packages/lf20_6p8hisgg.json',
    'https://assets2.lottiefiles.com/packages/lf20_ch1qp0yv.json',
    'https://assets3.lottiefiles.com/private_files/lf30_tmajwstf.json',
    'https://assets9.lottiefiles.com/packages/lf20_kd5rzej5.json',
    'https://assets2.lottiefiles.com/private_files/lf30_nuz1eeqj.json',
]
export default function SupportiveModal({
    open,
    title,
    message,
    onConfirm
}: {
    open: boolean,
    title: string | undefined,
    message: string | undefined,
    onConfirm: () => void
}) {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    var anim_src = ANIMATE_LINKS[Math.floor(Math.random() * ANIMATE_LINKS.length)];
    return (
        <div>
            <Dialog
                open={open}
                onClose={onConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <Player
                        src={anim_src}
                        autoplay
                        loop
                        style={{ height: '100px', width: '100px', }}
                        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                    />

                    <Typography variant='h4' color='success.main' ml={2}
                        fontFamily="cursive" textAlign={'center'}>
                        {title}
                    </Typography>
                    <Typography variant='h6' color='primary.main' fontFamily={"serif"} textAlign='center'>
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirm} variant='contained'>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
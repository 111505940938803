import * as React from 'react';
import { Box, Container, Typography, Button, Grid } from '@mui/material';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getPriority, ScoreType } from 'utils/SurveyUtil';
import { red, yellow, blue, green } from '@mui/material/colors'
import { AnalysisData } from 'config/constants';





export const PRIORITY_COLORS = {
    'high': { label: 'High Priority', color: red[400] },
    'medium': { label: 'Medium Priority', color: blue[400] },
    'low': { label: 'Low Priority', color: green[400] },
    'N/A': { label: 'N/A', color: 'gray' },
}
const ScoreBar = ({
    score,
    ...rest
}: {
    score: ScoreType,
}) => {
    const barWidth = 20
    const barHeight = 240
    const scoreWidth = barWidth + 10
    const scoreHeight = 10
    const getScoreBottom = () => {
        const scoreStepHeight = {
            low: 0,
            medium: 1,
            high: 2
        }
        const section_analysis = AnalysisData.filter(s => s.section_name == score.section_name)[0]
        if (!section_analysis) return 0
        const priority = getPriority(score.section_name, score.score)
        if (priority == 'N/A') return 0

        const priorityAccessor: 'high_priority' | 'medium_priority' | 'low_priority' = `${priority}_priority`
        let scorePercent = (score.score - section_analysis[priorityAccessor].start) / (section_analysis[priorityAccessor].end - section_analysis[priorityAccessor].start)
        if (priority == 'high') {
            scorePercent = (score.score - section_analysis[priorityAccessor].start) / (section_analysis.highest_score - section_analysis[priorityAccessor].start)
        }
        scorePercent = Math.min(scorePercent, 1)
        return scorePercent * (barHeight / 3) + scoreStepHeight[priority] * (barHeight / 3)
    }
    const scoreBottom = getScoreBottom()
    return <Box p={3} {...rest}>
        <Box sx={{ position: 'relative' }}>
            <Box sx={{ background: PRIORITY_COLORS.high.color, width: barWidth, height: barHeight / 3 }}></Box>
            <Box sx={{ background: PRIORITY_COLORS.medium.color, width: barWidth, height: barHeight / 3 }}></Box>
            <Box sx={{ background: PRIORITY_COLORS.low.color, width: barWidth, height: barHeight / 3 }}></Box>
            <Box sx={{
                background: 'black', width: scoreWidth, height: scoreHeight, position: 'absolute', bottom: scoreBottom,
                transform: `translate(-${(scoreWidth - barWidth) / 2}px, ${scoreHeight / 2}px)`
            }}></Box>
        </Box>
        <Box sx={{ width: barWidth, position: 'relative', height: 150 }}>
            <Typography sx={{
                position: 'absolute',
                right: 0,
                transform: 'rotate(300deg)',
                transformOrigin: '100% 0',
                whiteSpace: 'nowrap'
            }}>{score.section_name}</Typography>
        </Box>
    </Box>
}
export default function ResultTable({ scoreData }: {
    scoreData: ScoreType[]
}) {


    return (<Box sx={{ overflowX: 'auto' }}>
        <Box display='flex' justifyContent={'center'}>
            {
                scoreData.map((s, key) => {
                    return <ScoreBar score={s} key={key}/>
                })
            }
        </Box>
        {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {scoreData.map((section, key) => {
                const cellData = getCellData(section)
                return (
                    <Grid container>
                        <Grid item xs={8} sx={{
                            p: 1,
                            backgroundColor: 'lightgray'
                        }}>
                            <Typography>{section.section_name}</Typography>
                        </Grid>
                        <Grid item sx={{
                            backgroundColor: cellData.color,
                            color: 'white',
                            p: 1
                        }} xs={4}>

                            <Typography>{cellData.score} / {cellData.highest_score}</Typography>
                        </Grid>
                    </Grid>)
            })
            }

        </Box> */}
    </Box>
    );
}

import React from 'react';
import logo from './logo.svg';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import './App.css';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Root from './views/Root';
import Survey from './views/Survey';
import Profile from './views/Survey/Profile';
import Part1 from './views/Survey/Part1';
import Part2 from './views/Survey/Part2';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from 'redux/store';
import SurveyRoot from 'views/Survey/SurveyRoot';
import SurveyResult from 'views/Survey/SurveyResult';
import PaymentRoot from 'views/Payment/PaymentRoot';
import PaymentComplete from 'views/Payment/PaymentComplete';
import theme from "theme";
import SnapshotRoot from 'views/Snapshot/SnapshotRoot';
import SnapshotList from 'views/Snapshot/SnapshotList';
import SnapshotDetail from 'views/Snapshot/SnapshotDetail';
import AdminRoot from 'views/Admin/AdminRoot';
import SurveyAnswers from 'views/Admin/SurveyAnswers';
import AdminLogin from 'views/Admin/AdminLogin';
import TestPage from 'views/TestPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Navigate to='/survey/profile' />,
      },
      {
        path: "test",
        element: <TestPage />
      },
      {
        path: "admin",
        element: <AdminRoot />,
        children: [
          {
            path: "survey/answers/:checkout_session_id",
            element: <SurveyAnswers />,
          },
          {
            path: "/admin/login",
            element: <AdminLogin />,
          },
        ]
      },
      {
        path: "survey",
        element: <SurveyRoot />,
        children: [
          {
            path: "/survey/profile",
            element: <Profile />,
          },
          {
            path: "/survey/part1",
            element: <Part1 />,
          },
          {
            path: "/survey/part2",
            element: <Part2 />,
          },
          {
            path: "/survey/result",
            element: <SurveyResult />,
          },
        ]
      },
      {
        path: "snapshot",
        element: <SnapshotRoot />,
        children: [
          // {
          //   path: "/snapshot/list",
          //   element: <SnapshotList />
          // },
          {
            path: "/snapshot/detail/:checkout_session_id",
            element: <SnapshotDetail />
          },
        ]
      },
      {
        path: "payment",
        element: <PaymentRoot />,
        children: [
          {
            path: '/payment/complete/:CHECKOUT_SESSION_ID',
            element: <PaymentComplete />
          }
        ]
      }
    ],

  },
]);
function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>

          <RouterProvider router={router} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

import { default as React, useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { getPart2Score, getPriority, getSectionScore, ScoreType } from 'utils/SurveyUtil';
import { ProfileType, SymptomAnswerType } from 'model/symptom';
import { AnalysisData } from 'config/constants';
import { PRIORITY_COLORS } from 'views/Survey/ResultTable';
import moment from 'moment';
// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#eee'
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
        padding: 10,
        justifyContent: 'space-between'
    }
});
const ScoreBar = ({
    score
}: {
    score: ScoreType
}) => {
    const barWidth = 20
    const barHeight = 240
    const scoreWidth = barWidth + 10
    const scoreHeight = 10
    const getScoreBottom = () => {
        const scoreStepHeight = {
            low: 0,
            medium: 1,
            high: 2
        }
        const section_analysis = AnalysisData.filter(s => s.section_name == score.section_name)[0]
        if (!section_analysis) return 0
        const priority = getPriority(score.section_name, score.score)
        if (priority == 'N/A') return 0

        const priorityAccessor: 'high_priority' | 'medium_priority' | 'low_priority' = `${priority}_priority`
        let scorePercent = (score.score - section_analysis[priorityAccessor].start) / (section_analysis[priorityAccessor].end - section_analysis[priorityAccessor].start)
        if (priority == 'high') {
            scorePercent = (score.score - section_analysis[priorityAccessor].start) / (section_analysis.highest_score - section_analysis[priorityAccessor].start)
        }
        scorePercent = Math.min(scorePercent, 1)
        console.info('=== score ===', JSON.stringify(score, null, 4))
        return scorePercent * (barHeight / 3) + scoreStepHeight[priority] * (barHeight / 3)
    }
    const scoreBottom = getScoreBottom()
    return <View style={{ padding: 10 }}>
        <View style={{ position: 'relative' }}>
            <View style={{ backgroundColor: PRIORITY_COLORS.high.color, width: barWidth, height: barHeight / 3 }}></View>
            <View style={{ backgroundColor: PRIORITY_COLORS.medium.color, width: barWidth, height: barHeight / 3 }}></View>
            <View style={{ backgroundColor: PRIORITY_COLORS.low.color, width: barWidth, height: barHeight / 3 }}></View>
            <View style={{
                backgroundColor: 'black', width: scoreWidth, height: scoreHeight, position: 'absolute', bottom: scoreBottom,
                transform: `translate(-${(scoreWidth - barWidth) / 2}px, ${scoreHeight / 2}px)`
            }}></View>
        </View>
        <View style={{ position: 'relative', }}>
            <Text style={{
                width: 250,
                height: barWidth,
                position: 'absolute',
                top: barWidth,
                left: barWidth,
                transform: 'rotate(90deg)',
                transformOrigin: '0% 0',
            }}>{score.section_name}</Text>
        </View>
    </View>
}
// Create Document Component
export const SnapshotPDF = ({
    symptomAnswers,
    profile
}: {
    symptomAnswers: SymptomAnswerType[]
    profile: ProfileType | null
}) => {

    const [scoreData, setScoreData] = useState<ScoreType[]>([])
    useEffect(() => {
        window.scrollTo(0, 0)

        const scoreData_ = getPart2Score(symptomAnswers)
        setScoreData(scoreData_)
    }, [])
    const getAge = (birth_date: string | undefined) => {
        if (birth_date) {
            const birth_date_y = moment(birth_date).year()
            const now_date_y = moment().year()
            return now_date_y - birth_date_y
        } else {
            return null
        }

    }
    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={{ padding: 10, fontSize: 12, marginTop: 40 }}>
                    <Text style={{ fontSize: 18, textAlign: 'center' }}>
                        SexyStrongLife Core Priority Systems/Organs
                    </Text>
                </View>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 40
                }}>
                    {
                        scoreData.map(s => {
                            return <ScoreBar score={s} />
                        })
                    }
                </View>

            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View>
                        <Text style={{ fontSize: 14, textAlign: 'center' }}>User Name: {profile?.name}</Text>
                    </View>
                    <View>
                        <Text style={{ fontSize: 14, textAlign: 'center' }}>Date: {profile?.submit_date}</Text>
                    </View>
                    <View>
                        <Text style={{ fontSize: 14, textAlign: 'center' }}>Age: {getAge(profile?.birth_date)}</Text>
                    </View>
                    <View>
                        <Text style={{ fontSize: 14, textAlign: 'center' }}>Biological Gender: {profile?.birth_gender}</Text>
                    </View>
                </View>
                <View style={{ padding: 10 }}>
                    <View style={{ marginBottom: 20, padding: 10, backgroundColor: '#e2f0da', borderRadius: 10 }}>
                        <Text style={{ fontSize: 18 }}>Diet:</Text>
                        <Text style={{ fontSize: 14 }}>

                            There are 21 questions in the Diet section that asked you about categories of foods and beverages that you consume that may be at the core of your symptoms. Out of a possible 61 points,
                            you scored {
                                getSectionScore('DIET', symptomAnswers)?.score
                            }. Anything above 5 points suggests that you may wish to speak with a Nutritionists or explore Nutrigenomic and Food Intolerance lab testing to help you identify if those choices can be improved to reduce overall symptoms.
                        </Text>
                    </View>
                    <View style={{ marginBottom: 20, padding: 10, backgroundColor: '#ddebf8', borderRadius: 10 }}>
                        <Text style={{ fontSize: 18 }}>LIFESTYLE:</Text>
                        <Text style={{ fontSize: 14 }}>
                            There are 5 questions in the LifeStyle section for a total of 16 points. You scored {
                                getSectionScore('LIFESTYLE', symptomAnswers)?.score
                            }. LIFESTYLE was asked to help you to become aware of how stress and lack of exercise or over exercise can be apart of your core symptoms. It is suggested that you consider if you would benefit from outside help, a change of job, more or less excercise. Please reflect on laughter as well.</Text>
                    </View>
                    <View style={{ marginBottom: 20, padding: 10, backgroundColor: '#fff2cc', borderRadius: 10 }}>
                        <Text style={{ fontSize: 18 }}>ENVIRONMENT:</Text>
                        <Text style={{ fontSize: 14 }}>
                            There are 4 questions in ENVIRONMENT. If you answered yes to any of them, please explore your environment as a possible contributor to your symptoms.
                        </Text>
                    </View>
                </View>

                <View style={{ padding: 10, fontSize: 12, marginTop: 40 }}>
                    <View style={{ padding: 10, backgroundColor: '#8f000044', }}>
                        <Text>You should consult your physician before: beginning any program, exercise, body awareness techniques, or meditation; changing your diet; taking any nutritional or herbal supplement; or using any information provided by SexyStrongLife or any of our Coaches.</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
};
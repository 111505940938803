import { AnalysisData, Part1Data, Part2Data } from "config/constants"
import { SymptomAnswerType, SymptomType } from "model/symptom"
import { store } from 'redux/store'

export const getSectionScore = (section_name: string, symtomAnswers: SymptomAnswerType[]) => {
    const allSections = [...Part1Data.sections, ...Part2Data.sections]
    const section = allSections.filter(s => s.section_name == section_name)[0]
    if (section) {
        const symptomIds = section.symptoms.map(s => s.id)
        const maxScore = section.symptoms.reduce((a, b) => a + (b.options.length - 1), 0)
        const symtomAnswersForSection = symtomAnswers.filter(s => symptomIds.includes(s.id))
        const answerSum = symtomAnswersForSection.reduce((a, b) => a + b.value, 0)
        return {
            section_name: section.section_name,
            section_label: section.section_label,
            score: answerSum,
            maxScore: maxScore
        }
    } else {
        return null
    }
}
export type ScoreType = {
    section_name?: string,
    section_label?: string,
    score: number,
    maxScore: number,
}
export const getPart2Score = (symtomAnswers: SymptomAnswerType[]): ScoreType[] => {

    const score: ScoreType[] = []
    Part2Data.sections.map((section) => {
        const sectionScore = getSectionScore(section.section_name, symtomAnswers)
        if (sectionScore) {
            score.push(sectionScore)
        }
    })
    return score
}
export const getPriority = (section_name: string | undefined, score: number): 'high' | 'medium' | 'low' | 'N/A' => {
    const analys_section = AnalysisData.filter(a => a.section_name == section_name)[0]
    if (analys_section) {
        if (analys_section.low_priority.start <= score && analys_section.low_priority.end > score) {
            return 'low'
        }
        if (analys_section.medium_priority.start <= score && analys_section.medium_priority.end > score) {
            return 'medium'
        }
        if (analys_section.high_priority.start <= score && analys_section.high_priority.end > score) {
            return 'high'
        }
    } else {
        console.info('=== not found priority ===', JSON.stringify(section_name, null, 4))

    }
    return 'N/A'
}
import { createTheme, ThemeProvider, styled, responsiveFontSizes,  } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
const baseTheme = {
  palette: {
    primary: { main: "#00acc1", dark: "#1d405d" },
    secondary: { main: "#4caf50" },
    info: { main: "#00acc1" },
  }
}
const defaultTheme = createTheme(baseTheme);

let theme = {
  ...defaultTheme,
}
theme = responsiveFontSizes(theme);

export default theme;

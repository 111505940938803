import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  LOADING_COUNT: 0,
  REDIRECT_PATH: null,
  COOKIE_ALLOW: 'undefined',
  PREDICT_TOUR_ALLOW: true,
  SHOW_COMPANY_DETAIL_TOUR: true
}

export const slice = createSlice({
  name: 'appstate',
  initialState,
  reducers: {
    addLoading: (state, { payload: data }) => {
      return { ...state, LOADING_COUNT: 1 }
    },
    removeLoading: (state, { payload: data }) => {
      return { ...state, LOADING_COUNT: 0 }
    },
    setRedirectPath: (state, { payload: data }) => {
      return { ...state, REDIRECT_PATH: data }
    },
    setCookieAllow: (state, { payload: data }) => {
      return { ...state, COOKIE_ALLOW: data }
    },
    setPredictTourAllow: (state, { payload: data }) => {
      return { ...state, PREDICT_TOUR_ALLOW: data }
    },
    setCompanyDetailTourAllow: (state, { payload: data }) => {
      return { ...state, SHOW_COMPANY_DETAIL_TOUR: data }
    },
  },
});
// Exports
export const {
  addLoading,
  removeLoading,
  setRedirectPath,
  setCookieAllow,
  setPredictTourAllow,
  setCompanyDetailTourAllow,
} = slice.actions


export default slice.reducer;
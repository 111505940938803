import moment from "moment"

export const getAge = (birth_date: string | undefined) => {
    if (birth_date) {
        const birth_date_y = moment(birth_date).year()
        const now_date_y = moment().year()
        return now_date_y - birth_date_y
    } else {
        return null
    }

}
import { Alert, Box, Container, Typography } from '@mui/material';
import { CallApi } from 'utils/Request';
import { getPart2Score, ScoreType } from 'utils/SurveyUtil';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import ResultTable, { PRIORITY_COLORS } from './ResultTable';

export default function () {
    const formdata = useSelector((state: RootState) => state.formdata)
    const appdata = useSelector((state: RootState) => state.appdata)
    const symtomAnswers = formdata.SYMPTOM_ANSWERS || []

    const [scoreData, setScoreData] = useState<ScoreType[]>([])
    useEffect(() => {
        window.scrollTo(0, 0)

        const scoreData_ = getPart2Score(symtomAnswers)
        setScoreData(scoreData_)
    }, [])

    return <Box my={6} sx={{ minHeight: '90vh' }}>
        <Container maxWidth="xl">
            <Box my={6}>
                <Alert icon={false} >
                    <Typography fontFamily={'serif'} fontStyle='italic' variant='h6'>
                        Here is a snapshot of your SexyStrongLife Optimal Health Priority results. Your answers
                        provided a total for each system or organ that was tested, which resulted in a low,
                        moderate or high priority assessment. Your goal is to have all systems and organs in
                        Low Priority. If a system or organ is in Moderate or Hight Priority, it is suggested that
                        you continue to investigate what is at the core of your symptoms/distress. You may wish
                        to consider exploring Functional lab tests that may provide deeper answers for you with
                        our team at SexyStrongLife or explore on your own using our resources on our website.
                    </Typography>
                    <Typography fontFamily={'serif'} variant='body2' my={2}>
                        Wishing you full health and a SexyStrongLife
                    </Typography>
                    <Typography fontFamily={'serif'} variant='body2'>
                        Sheri Levy, FDN-P, CHHP, CN-PSYCH, CGP, Thyroid Expert, CFLN, xo
                    </Typography>
                </Alert>
            </Box>
            <Alert severity="error">You should consult your physician before: beginning any program, exercise, body awareness techniques, or meditation; changing your diet; taking any nutritional or herbal supplement; or using any information provided by SexyStrongLife or any of our Coaches.</Alert>

            <Box my={6}>
                <Box display='flex' justifyContent={'center'}>
                    <Box sx={{
                        background: PRIORITY_COLORS.high.color,
                        px: 1,
                        m: 1
                    }}>
                        <Typography color='white' variant='caption'>
                            {PRIORITY_COLORS.high.label}
                        </Typography>
                    </Box>
                    <Box sx={{
                        background: PRIORITY_COLORS.medium.color,
                        px: 1,
                        m: 1
                    }}>
                        <Typography color='white' variant='caption'>
                            {PRIORITY_COLORS.medium.label}
                        </Typography>
                    </Box>
                    <Box sx={{
                        background: PRIORITY_COLORS.low.color,
                        px: 1,
                        m: 1
                    }}>
                        <Typography color='white' variant='caption'>
                            {PRIORITY_COLORS.low.label}
                        </Typography>
                    </Box>
                </Box>
                {/* <Alert severity="info">Symptom Totals. Your symptom burden total is listed on the left of the /. The total amount indicating the end range of the High Priority is on the right.</Alert> */}
                <Box mt={4} >
                    <ResultTable
                        scoreData={scoreData}
                    />
                </Box>
            </Box>
        </Container>
    </Box>
}
import { Box, Typography, Grid } from '@mui/material';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Part1Data, Part2Data } from 'config/constants'
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const TOTAL_SECTIONS = Part1Data.sections.length + Part2Data.sections.length
const LIVE_BUY_STRIPE = 'https://buy.stripe.com/fZe7uB70L9gx68E3cc'
const DEMO_BUY_STRIPE = 'https://buy.stripe.com/test_aEU9BacqT8XD0z63cc'
function Footer() {
    return <Box sx={{
        backgroundColor: 'info.dark',
        px: 2,
        py: 1
    }}>
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box>
                    <Typography color='white'>SEXYSTRONGLIFE.COM</Typography>
                </Box>
            </Grid>
            <Grid item md={6}>
                <Box>
                    <Typography color='white'>ALL RIGHTS RESERVED</Typography>
                </Box>
            </Grid>
        </Grid>
    </Box>
}
export default function () {
    const location = useLocation()
    const formdata = useSelector((state: RootState) => state.formdata)
    const appdata = useSelector((state: RootState) => state.appdata)
    const [paymentCompleted, setPaymentCompleted] = useState(false)

    const progress = formdata.SURVEY_PROGRESS
    const [showProgress, setShowProgress] = useState(false)
    useEffect(() => {
        if (location.pathname == "/survey/part1" || location.pathname == "/survey/part2") {
            setShowProgress(true)
        } else {
            setShowProgress(false)
        }
    }, [location])
    useEffect(() => {
        const isResultPage = location.pathname == "/survey/result"
        if (appdata) {
            const isPaid = appdata.PAYMENT_COMPLETED && appdata.CHECKOUT_SESSION_ID && appdata.CHECKOUT_SESSION_ID.length > 0
            if (!isPaid) {
                window.location.href = LIVE_BUY_STRIPE
            } else {
                setPaymentCompleted(true)
            }
        } else {
            window.location.href = LIVE_BUY_STRIPE
        }
    }, [appdata])
    if (!paymentCompleted) {
        return <Typography>...Loading</Typography>
    }
    return <Box>

        <Outlet />
        {showProgress && <Box sx={{
            position: 'fixed', left: 0, right: 0, top: 0, zIndex: 1400,
            background: 'white',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;'
        }}>
            <LinearProgressWithLabel value={progress} />
        </Box>}
        <Footer />
    </Box>
}
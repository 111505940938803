import { PartType, SectionType, SymptomType } from "../model/symptom"




export const Part1Data: PartType = {
    label: 'PART1',
    description: 'Use the last 90-Days as a reference along with the key to answer each questions. Please select the number that applies to your consumption or action.',
    options: [{ value: 0, label: 'I do not consume or use' },
    { value: 1, label: 'Monthly, I consume or use 2-3x' },
    { value: 2, label: 'Weekly, I consume or use' },
    { value: 3, label: 'Daily, I consume or use' }],
    sections: [
        {
            section_label: 'DIET',
            section_name: 'DIET',
            symptoms: [
                { id: 1, options: [0, 1, 2, 3], label: 'Alcohol' },
                {
                    id: 2, options: [0, 1, 2, 3], label: 'Artificial sweeteners*',
                    note: 'Artificial sweeteners may be in gum/sugarless mints, diet sodas, non-fat yogurt, artificial sweetener packets, diet foods'
                },
                {
                    id: 3, options: [0, 1, 2, 3],
                    label: 'Candy, desserts, foods with corn syrup'
                },
                {
                    id: 4, options: [0, 1, 2, 3],
                    label: 'Carbonated sodas or mineral waters'
                },
                {
                    id: 5, options: [0, 1, 2, 3],
                    label: 'Chewing tobacco'
                },
                {
                    id: 6, options: [0, 1, 2, 3],
                    label: 'Cigarettes, cigars'
                },
                {
                    id: 7, options: [0, 1, 2, 3],
                    label: 'Vape, pipes'
                },
                {
                    id: 8, options: [0, 1, 2, 3],
                    label: 'Caffeinated beverages'
                },
                {
                    id: 9, options: [0, 1, 2, 3],
                    label: 'Fast foods or Microwave'
                },
                {
                    id: 10, options: [0, 1, 2, 3],
                    label: 'Fried foods: fries, proteins, etc.'
                },
                {
                    id: 11, options: [0, 1, 2, 3],
                    label: 'Sliced Non-Organic Deli Meats'
                },
                {
                    id: 12, options: [0, 1, 2, 3],
                    label: 'Hydrogenated butters/margarine'
                },
                {
                    id: 13, options: [0, 1, 2, 3],
                    label: 'Animal Milk products/butter/ice cream/cheese'
                },
                {
                    id: 14, options: [0, 1, 2, 3],
                    label: 'Have you had radiation treatments? (0 = no 1 = yes)'
                },
                {
                    id: 15, options: [0, 1, 2, 3],
                    label: 'Refined flour, baked goods, breads, muffins'
                },
                {
                    id: 16, options: [0, 1, 2, 3],
                    label: 'Vitamins and minerals that were not specifically selected for you'
                },
                {
                    id: 17, options: [0, 1, 2, 3],
                    label: 'Distilled or reverse osmosis water'
                },
                {
                    id: 18, options: [0, 1, 2, 3],
                    label: 'Tap water from a sink without a filter'
                },
                {
                    id: 19, options: [0, 1, 2, 3],
                    label: 'Filtered water in plastic bottles'
                },
                {
                    id: 20, options: [0, 1, 2, 3],
                    label: 'Well water not recently tested for purity'
                },
                {
                    id: 21, options: [0, 1, 2, 3],
                    label: 'Do you feel like you diet often to manage your weight?'
                },
            ]
        },
        {
            section_label: 'LIFESTYLE',
            section_name: 'LIFESTYLE',
            hide_index: true,
            hide_guide: true,
            symptoms: [
                { id: 22, options: [0, 1, 2, 3,], label: 'How many times do you exercise per week? Choose "0" if 2 or more times a week. Choose "1" if 1 time a week, Choose "2" if 1 or 2 times a month, Choose "3" if never or less than once a month' },
                { id: 23, options: [0, 1, 2, 3,], label: 'Have you changed jobs? Choose 0 if over 12 months ago. Choose 2 if within the last 6 months. Choose 3 if within the last two months' },
                { id: 24, options: [0, 1, 2, 3,], label: 'Divorced? Choose "0" if over 2 yrs. ago. Choose "1" if within the last 2 yrs. Choose "2" if within the last year. Choose "3" if within the last 6 months' },
                { id: 25, options: [0, 1, 2, 3,], label: 'Do you work over 60 hours a week? 0=never, 1 = occasionally, 2 = usually, 3 = always' },
                { id: 26, options: [0, 1, 2, 3,], label: 'Do you have young children under the age of 10? Choose "0" if no children. Choose "1" is one child. Choose "2" children. Choose "3" if 3 or more children' },
            ]
        },
        {
            section_label: 'ENVIRONMENT',
            section_name: 'ENVIRONMENT',
            hide_guide: true,
            hide_index: true,
            symptoms: [
                { id: 27, options: [0, 1], label: 'Do you or have you ever lived in a moldy home that required remediation? Choose "0" = no, "1" = yes' },
                { id: 28, options: [0, 1], label: 'Do you currently live in a place that is cluttered, hard to walk around? Choose "0" = no, "1" = yes' },
                { id: 29, options: [0, 1], label: 'Do you currently live in an area where the sun is less available? Choose "0" = no, "1" = yes' },
                { id: 30, options: [0, 1], label: 'Do you currently live in a 25 mile radius of a powerplant, chemical plant, or environmental disaster? Choose "0" = "no", "1" = yes' },
            ]
        },
        {
            section_label: 'MEDICATIONS',
            section_name: 'MEDICATIONS',
            hide_guide: true,
            hide_index: true,
            description: `Please indicate any medication you are currently taking or have taken within the last month by typing in ( "1" = yes)`,
            skippable: true,
            skip_text: 'If you do not take any medication or over-the-counter medications, please skip this section by clicking',
            symptoms: [
                { id: 31, options: [0, 1], label: 'Antacids, Pepcid,Tums, etc.' },
                { id: 32, options: [0, 1], label: 'Antianxiety medications' },
                { id: 33, options: [0, 1], label: 'Antibiotics' },
                { id: 34, options: [0, 1], label: 'Anticonvulsants' },
                { id: 35, options: [0, 1], label: 'Antidepressants' },
                { id: 36, options: [0, 1], label: 'Antifungals' },
                { id: 37, options: [0, 1], label: 'Aspirin/Ibuprofen' },
                { id: 38, options: [0, 1], label: 'Asthma inhalers' },
                { id: 39, options: [0, 1], label: 'Beta blockers' },
                { id: 40, options: [0, 1], label: ' Birth control pills, hormonal contraception, implant' },
                { id: 41, options: [0, 1], label: 'Chemotherapy' },
                { id: 42, options: [0, 1], label: 'Cholesterol lowering medications/statins' },
                { id: 43, options: [0, 1], label: 'Cortisone/steroids' },
                { id: 44, options: [0, 1], label: 'Diabetic medications/metformin/insulin/etc.' },
                { id: 45, options: [0, 1], label: 'Diuretics' },
                { id: 46, options: [0, 1], label: 'Estrogen or progesterone pharmaceutical prescription or bio identical' },
                { id: 47, options: [0, 1], label: 'Estrogen or progesterone pharmaceutical bio identical/natural' },
                { id: 48, options: [0, 1], label: 'Heart medications' },
                { id: 49, options: [0, 1], label: 'High blood pressure medications' },
                { id: 50, options: [0, 1], label: 'Laxatives' },
                { id: 51, options: [0, 1], label: 'Recreational drugs', extra_input: { label: 'list:' } },
                { id: 52, options: [0, 1], label: 'Relaxants/Sleeping pills' },
                { id: 53, options: [0, 1], label: 'Testosterone (natural or prescription)' },
                { id: 54, options: [0, 1], label: 'Thyroid medication' },
                { id: 55, options: [0, 1], label: 'Acetaminophen (Tylenol)' },
                { id: 56, options: [0, 1], label: 'Ulcer medications' },
                { id: 57, options: [0, 1], label: 'Sildenafil citrate (Viagra)' },
            ]
        }
    ]
}
export const Part2Data: PartType = {
    label: 'PART2',
    description: 'Choose the appropriate number based on the level and frequency of your symptom in the fields under the respective number.',
    options: [
        { value: 0, label: 'never, no symptom' },
        { value: 1, label: 'yes, symptom does occur, rarely occurs (monthly), or is a mild symptom' },
        { value: 2, label: 'yes, symptom does occur. It is moderate in discomfort and/or occurs weekly' },
        { value: 3, label: 'yes, symptom does occur. It is a sever symptom and/or occurs frequently (daily or ongoing)' },
    ],
    sections: [
        {
            section_label: 'Section 1',
            section_name: 'Upper G.I.',
            supportive_title: 'Fantastic!',
            supportive_message: `You’re ready for the next level! Respond to questions using the last 90-days as a reference.`,
            symptoms: [
                { id: 58, options: [0, 1, 2, 3], label: "Do you experience any belching or gas 1 hr. after eating?" },
                { id: 59, options: [0, 1, 2, 3], label: "Heartburn or acid reflux" },
                { id: 60, options: [0, 1, 2, 3], label: "Bloating within 1 hr. after eating" },
                { id: 61, options: [0, 1,], label: `Vegan Diet? ("0" = no, "1" = yes)` },
                { id: 62, options: [0, 1, 2, 3], label: "Bad breath (halitosis)" },
                { id: 63, options: [0, 1, 2, 3], label: "Loss of taste for meat, meaning not a choice to give it up, but you don't crave it" },
                { id: 64, options: [0, 1, 2, 3], label: "Does your sweat have a strong odor" },
                { id: 65, options: [0, 1, 2, 3], label: "Stomach upset by taking vitamins" },
                { id: 66, options: [0, 1, 2, 3], label: "Sense of feeling excess fullness after a small meal, like your stomach is stuffed" },
                { id: 67, options: [0, 1, 2, 3], label: "Do you find that you prefer to skip breakfast to feel better longer?" },
                { id: 68, options: [0, 1, 2, 3], label: "Do you feel better if you don't eat?" },
                { id: 69, options: [0, 1, 2, 3], label: "Do you find you are sleepy after eating?" },
                { id: 70, options: [0, 1, 2, 3], label: "Fingernails chip, peel, break easily" },
                { id: 71, options: [0, 1, 2, 3], label: "Have you ever been told you are anemic and unresponsive to iron?" },
                { id: 72, options: [0, 1, 2, 3], label: "Stomach pains or cramps after eating" },
                { id: 73, options: [0, 1, 2, 3], label: "Diarrhea, chronic, most every waste elimination" },
                { id: 74, options: [0, 1, 2, 3], label: "Diarrhea, shortly after a meal" },
                { id: 75, options: [0, 1, 2, 3], label: "Any black or tarry-colored stool?" },
                { id: 76, options: [0, 1, 2, 3], label: "Do you notice undigested food in stool - not just corn." },
            ]
        },
        {
            section_label: 'Section 2',
            section_name: 'Liver Gallbladder',
            symptoms: [
                { id: 77, options: [0, 1, 2, 3], label: "Sharp pain between shoulder blades" },
                { id: 78, options: [0, 1, 2, 3], label: "Stomach upset by greasy foods" },
                { id: 79, options: [0, 1, 2, 3], label: "Do you experience greasy or shiny stools that leave a track on the bottom of the bowl" },
                { id: 80, options: [0, 1, 2, 3], label: "Nausea" },
                { id: 81, options: [0, 1, 2, 3], label: "Sea, car, airplane or motion sickness" },
                { id: 82, options: [0, 1], label: "History of morning sickness, not with pregnancy (0 = no, 1 = yes)" },
                { id: 83, options: [0, 1, 2, 3], label: "Do you have light or clay colored stools?" },
                { id: 84, options: [0, 1, 2, 3], label: "Dry skin, itchy feet or skin that peels on your feet" },
                { id: 85, options: [0, 1, 2, 3], label: "Headache over your eyes" },
                { id: 86, options: [0, 1, 2, 3], label: "Gallbladder attacks (0=never, 1= years ago, 2= within last year, 3 = within past 3 months)" },
                { id: 87, options: [0, 1], label: "Have you had your Gallbladder removed? (0 = no, 1 = yes)" },
                { id: 88, options: [0, 1, 2, 3], label: "Bitter taste in mouth, especially after meals" },
                { id: 89, options: [0, 1], label: "Do you become sick if you were to drink wine? (0 = no, 1 = yes)" },
                { id: 90, options: [0, 1], label: "Easily intoxicated if you were to drink wine (0 = no, 1 = yes)" },
                { id: 91, options: [0, 1], label: "Are you a recovering alcoholic? ( 0 = no, 1 = yes)" },
                { id: 92, options: [0, 1], label: "Do you have a history of drug or alcohol abuse? ( 0 = no, 1 = yes)" },
                { id: 93, options: [0, 1], label: "History of hepatitis ( 0 = no, 1 = yes)" },
                { id: 94, options: [0, 1], label: "Long-term history of prescription or recreational drugs? ( 0 = no, 1 = yes)" },
                { id: 95, options: [0, 1, 2, 3], label: "Are you sensitive to chemicals? (Perfumes, cleaning agents, etc)" },
                { id: 96, options: [0, 1, 2, 3], label: "Are you sensitive to tobacco smoke?" },
                { id: 97, options: [0, 1, 2, 3], label: "Do you have exposure to diesel fumes?" },
                { id: 98, options: [0, 1, 2, 3], label: "Any pain under your right side of your rib cage when you push?" },
                { id: 99, options: [0, 1, 2, 3], label: "Any hemorrhoids or varicose veins?" },
                { id: 100, options: [0, 1, 2, 3], label: "Do you consume NutraSweet (aspartame, diet soda, sugarless gum/yogurt)" },
                { id: 101, options: [0, 1, 2, 3], label: "Are you aware of a sensitivity to NutraSweet (aspartame)" },
                { id: 102, options: [0, 1, 2, 3], label: "Do you suffer from chronic fatigue or Fibromyalgia?" },
            ]
        },
        {
            section_label: 'Section 3',
            section_name: 'Small Intestine',
            symptoms: [
                { id: 103, options: [0, 1, 2, 3], label: " Do you have any Food Allergies that require an epi-pen, medical treatment, or present in hives?" },
                { id: 104, options: [0, 1, 2, 3], label: " Abdominal bloating 1 to 2 hours after eating?" },
                { id: 105, options: [0, 1], label: " Do specific foods make you tired or bloated ( 0 = no, 1 = yes)" },
                { id: 106, options: [0, 1, 2, 3], label: " Does your pulse speed up after eating?" },
                { id: 107, options: [0, 1, 2, 3], label: " Do you have any airborne allergies?" },
                { id: 108, options: [0, 1, 2, 3], label: " Experience hives?" },
                { id: 109, options: [0, 1, 2, 3], label: ' Sinus congestion, "stuffy head"' },
                { id: 110, options: [0, 1, 2, 3], label: " Crave noodles or bread?" },
                { id: 111, options: [0, 1, 2, 3], label: " Do you find that you alternate between constipation and diarrhea?" },
                { id: 112, options: [0, 1, 2, 3], label: " Crohn's disease? ( 0 = no, 1 = yes in the past, 2 = currently mild, 3 = severe)" },
                { id: 113, options: [0, 1, 2, 3], label: " Wheat or grain sensitivity?" },
                { id: 114, options: [0, 1, 2, 3], label: " Dairy sensitivity?" },
                { id: 115, options: [0, 1], label: " Are there foods that you could not give up because you feel you must eat them? ( 0 = no. 1 = yes)" },
                { id: 116, options: [0, 1, 2, 3], label: " Asthma, sinus infection, stuffy nose?" },
                { id: 117, options: [0, 1, 2, 3], label: " Bizarre vivid dreams, nightmares" },
                { id: 118, options: [0, 1, 2, 3], label: " Do you use over-the-counter pain medications" },
                { id: 119, options: [0, 1, 2, 3], label: " Do you feel spacey or unreal at times?" },
            ]
        },
        {
            section_label: 'Section 4',
            section_name: 'Large Intestine',
            symptoms: [
                { id: 120, options: [0, 1, 2, 3], label: "Does your anus itch?" },
                { id: 121, options: [0, 1, 2, 3], label: "Do you have a coated white tongue?" },
                { id: 122, options: [0, 1, 2, 3], label: "Do you feel worse being in a moldy or musty place?" },
                { id: 123, options: [0, 1, 2, 3], label: "Antibiotics? Total how many times 0=never, 1 = <1 month, 2= <3 months, 3 = >3 months" },
                { id: 124, options: [0, 1, 2, 3], label: "Any fungal or yeast infections?" },
                { id: 125, options: [0, 1, 2, 3], label: 'Ring worm, "jock itch", athletes foot, nail fungus?' },
                { id: 126, options: [0, 1, 2, 3], label: "Do you notice if your yeast symptoms increase with sugar, starch or alcohol?" },
                { id: 127, options: [0, 1, 2, 3], label: "Any stools that are hard or difficult to pass?" },
                { id: 128, options: [0, 1, 2, 3], label: "History of parasites ( 0 = no, 1 = yes)" },
                { id: 129, options: [0, 1, 2, 3], label: "Less than one bowel movement a day?" },
                { id: 130, options: [0, 1, 2, 3], label: "Does your stool have corners or edges, or is it flat or ribbon shaped?" },
                { id: 131, options: [0, 1, 2, 3], label: "Are your stool loose (not well formed)" },
                { id: 132, options: [0, 1, 2, 3], label: "Irritable bowel or mucus colitis" },
                { id: 133, options: [0, 1, 2, 3], label: "Blood in your stool?" },
                { id: 134, options: [0, 1, 2, 3], label: "Mucus in your stool?" },
                { id: 135, options: [0, 1, 2, 3], label: "Excessive foul smelling lower bowel gas" },
                { id: 136, options: [0, 1, 2, 3], label: "Any bad breath or strong body odors?" },
                { id: 137, options: [0, 1, 2, 3], label: "Take fingers and press along the outer sides of your thighs. Does it hurt?" },
                { id: 138, options: [0, 1, 2, 3], label: "Cramping in your lower abdominal region" },
                { id: 139, options: [0, 1, 2, 3], label: "Dark circles under your eyes" },
            ]
        },
        {
            section_label: 'Section 5',
            supportive_message: 'This time matters. Your body is grateful you are learning its language and checking in. Keep going!',
            section_name: 'Mineral Needs',
            symptoms: [
                { id: 140, options: [0, 1], label: `History of carpal tunnel syndrome (0= no 1 = yes)` },
                { id: 141, options: [0, 1], label: `History of lower right abdominal pains ( 0 = no, 1 = yes)` },
                { id: 142, options: [0, 1], label: `History of stress fracture (0 = no, 1 = yes)` },
                { id: 143, options: [0, 1, 2, 3], label: `Bone loss, shrinking, reduced density on a scan ` },
                { id: 144, options: [0, 1], label: `Are you shorter than you use to be? ( 0 = no, 1 = yes)` },
                { id: 145, options: [0, 1, 2, 3], label: `Do you experience calf, foot or toe cramps while at rest?` },
                { id: 146, options: [0, 1, 2, 3], label: `Any cold sores, fever blisters or herpes lesions?` },
                { id: 147, options: [0, 1, 2, 3], label: `Frequent fevers?` },
                { id: 148, options: [0, 1, 2, 3], label: `Frequent skin rashes and/or hives?` },
                { id: 149, options: [0, 1], label: `Herniated disc ( 0 = no, 1 = yes)` },
                { id: 150, options: [0, 1, 2, 3], label: `Excessively flexible joints, can go past the splits, double jointed` },
                { id: 151, options: [0, 1, 2, 3], label: `Joints pop or click` },
                { id: 152, options: [0, 1, 2, 3], label: `Pain or swelling in joints` },
                { id: 153, options: [0, 1, 2, 3], label: `Bursitis or tendonitis` },
                { id: 154, options: [0, 1], label: `History of bone spurs (0 = no, 1 = yes)` },
                { id: 155, options: [0, 1, 2, 3], label: `Morning stiffness when you wake is it hard to get out of bed` },
                { id: 156, options: [0, 1, 2, 3], label: `Nausea with vomiting` },
                { id: 157, options: [0, 1, 2, 3], label: `Crave chocolate` },
                { id: 158, options: [0, 1, 2, 3], label: `Feet have a strong odor` },
                { id: 159, options: [0, 1, 2, 3], label: `History of anemia` },
                { id: 160, options: [0, 1, 2, 3], label: `Whites or eyes appear blue tinted` },
                { id: 161, options: [0, 1, 2, 3], label: `Hoarseness in throat when you speak` },
                { id: 162, options: [0, 1, 2, 3], label: `Any difficulty swallowing?` },
                { id: 163, options: [0, 1, 2, 3], label: `Do you feel like there is a lump in your throat?` },
                { id: 164, options: [0, 1, 2, 3], label: `Dry mouth, eyes and/nose` },
                { id: 165, options: [0, 1, 2, 3], label: `Do you gag easily?` },
                { id: 166, options: [0, 1, 2, 3], label: `Do you have any white spots on your fingernails or toenails?` },
                { id: 167, options: [0, 1, 2, 3], label: `Have you noticed that your cuts heal slowly and/or you scar easily?` },
                { id: 168, options: [0, 1, 2, 3], label: `Before CoviD did you notice that you had a decreased sense of taste or smell?  If you did not get CoviD with those symptoms, do you notice a decreased sense of taste or smell?` },
            ]
        },
        {
            section_label: 'Section 6',
            section_name: 'Essential Fatty Acids',
            symptoms: [
                { id: 169, options: [0, 1, 2, 3], label: `Do you find that you crave fatty meats or greasy foods like chips, fries, nut butters?` },
                { id: 170, options: [0, 1, 2, 3], label: `Have you been on a low or reduced fat diet? (0=never, 1 = years ago, 2 = within past year, 3=currently)` },
                { id: 171, options: [0, 1, 2, 3], label: `Do you notice that you have tension headaches at the base of your skull?` },
                { id: 172, options: [0, 1, 2, 3], label: `Do you get headaches when you are out in the hot sun?` },
                { id: 173, options: [0, 1, 2, 3], label: `Do you sunburn easily or suffer from sun poisoning often?` },
                { id: 174, options: [0, 1, 2, 3], label: `Do your muscles easily fatigue when walking or exercising?` },
                { id: 175, options: [0, 1, 2, 3], label: `Any dry flaky skin on your heels of your feet, body, or scalp/ dandruff?` },
            ]
        },
        {
            section_label: 'Section 7',
            section_name: 'Sugar Handling',
            symptoms: [
                { id: 176, options: [0, 1, 2, 3], label: `Do you awaken a few hours after you fall asleep, is it hard to get back to sleep?` },
                { id: 177, options: [0, 1, 2, 3], label: `Crave sweets?` },
                { id: 178, options: [0, 1, 2, 3], label: `Do you experience times of binging or feel uncontrolled in your eating?` },
                { id: 179, options: [0, 1, 2, 3], label: `Excessive appetite, feeling like you are never satisfied or eat more than you should?` },
                { id: 180, options: [0, 1, 2, 3], label: `Do you crave coffee/caffeine or sugar in the afternoon?` },
                { id: 181, options: [0, 1, 2, 3], label: `Do you feel sleepy in the afternoon?` },
                { id: 182, options: [0, 1, 2, 3], label: `Have you noticed that you get fatigued but it is then relieved by eating something?` },
                { id: 183, options: [0, 1, 2, 3], label: `Do you get headaches if meals are skipped or delayed?` },
                { id: 184, options: [0, 1, 2, 3], label: `Do you find your get irritable before you are able to eat a meal?` },
                { id: 185, options: [0, 1, 2, 3], label: `Does your body feel shaky if meals are delayed?` },
                { id: 186, options: [0, 1, 2, 3], label: `Any family members with diabetes? (0 = none, 1 = 1-2, 2 = 3-4, 3 = more than 4)` },
                { id: 187, options: [0, 1, 2, 3], label: `Do you feel like you are frequently thirsty?` },
                { id: 188, options: [0, 1, 2, 3], label: `Do you experience frequent urination not associated with excessive water intake?` },
            ]
        },
        {
            section_label: 'Section 8',
            supportive_message: 'Answering questions is the easy part. You are 1/2 way there!',
            section_name: 'Vitamin Needs',
            symptoms: [
                { id: 189, options: [0, 1, 2, 3], label: `Do your muscles become easily fatigued when just walking around or light exercise?` },
                { id: 190, options: [0, 1, 2, 3], label: `Do you feel exhausted or sore after moderate exercise?` },
                { id: 191, options: [0, 1, 2, 3], label: `Are you vulnerable to insect bites? Are you the person who is often bitten if a bug is around?` },
                { id: 192, options: [0, 1, 2, 3], label: `Have you noticed loss of muscle tone or heaviness in the arms/legs?` },
                { id: 193, options: [0, 1, 2, 3], label: `Has a doctor ever told you if you have an enlarged heart or congestive heart failure?` },
                { id: 194, options: [0, 1], label: `Is your pulse below 65 beats per minute (0 = no, 1 = yes)` },
                { id: 195, options: [0, 1, 2, 3], label: `Do you experience ringing in your ears (Tinnitus)` },
                { id: 196, options: [0, 1, 2, 3], label: `Any experiences of numbing, tingling or itching in your hands and feet?` },
                { id: 197, options: [0, 1, 2, 3], label: `Do you feel depressed?` },
                { id: 198, options: [0, 1, 2, 3], label: `Fear of impending doom?` },
                { id: 199, options: [0, 1, 2, 3], label: `Would you describe yourself as a worried, apprehensive and/or anxious?` },
                { id: 200, options: [0, 1, 2, 3], label: `Nervous or agitated?` },
                { id: 201, options: [0, 1, 2, 3], label: `Feelings of insecurity?` },
                { id: 202, options: [0, 1, 2, 3], label: `Do you notice that your heart races?` },
                { id: 203, options: [0, 1, 2, 3], label: `Can you hear your heartbeat on your pillow at night?` },
                { id: 204, options: [0, 1, 2, 3], label: `Would you describe yourself as a worried, apprehensive and/or anxious?` },
                { id: 205, options: [0, 1, 2, 3], label: `Do you experience night sweats?` },
                { id: 206, options: [0, 1, 2, 3], label: `Restless leg syndrome?` },
                { id: 207, options: [0, 1, 2, 3], label: `Cracks at the corners of your mouth (Cheilosis)` },
                { id: 208, options: [0, 1, 2, 3], label: `Any fragile skin, that is easily chaffed, as in shaving` },
                { id: 209, options: [0, 1, 2, 3], label: `Do you have any polyps or warts?` },
                { id: 210, options: [0, 1, 2, 3], label: `Are you sensitive to MSG?` },
                { id: 211, options: [0, 1, 2, 3], label: `Do you wake up without remembering your dreams? ` },
                { id: 212, options: [0, 1, 2, 3], label: `If you felt the back of your arms, would you have small bumps?` },
                { id: 213, options: [0, 1, 2, 3], label: `Does strong light at night irritate your eyes?` },
                { id: 214, options: [0, 1, 2, 3], label: `Any nose bleeds and/or do you tend to bruise easily` },
                { id: 215, options: [0, 1, 2, 3], label: `Have you noticed any bleeding gums when you floss or brush your teeth?` },
            ]
        },
        {
            section_label: 'Section 9',
            section_name: 'Adrenal',
            symptoms: [
                { id: 216, options: [0, 1, 2, 3], label: `Do you tend to have more energy at night? "A night person"?` },
                { id: 217, options: [0, 1, 2, 3], label: `Do you have difficulty winding down and falling asleep?` },
                { id: 218, options: [0, 1, 2, 3], label: `Are you a slow starter in the morning?` },
                { id: 219, options: [0, 1, 2, 3], label: `Do you tend to be hyped up and have trouble calming down?` },
                { id: 220, options: [0, 1, 2, 3], label: `Is your blood pressure over 120/80?` },
                { id: 221, options: [0, 1, 2, 3], label: `Do you experience a headache after exercising` },
                { id: 222, options: [0, 1, 2, 3], label: `After drinking coffee/caffeine do you feel wired and jittery` },
                { id: 223, options: [0, 1, 2, 3], label: `Do you clench or grind your teeth?` },
                { id: 224, options: [0, 1, 2, 3], label: `Would you describe yourself as calm on the inside but troubled on the outside.` },
                { id: 225, options: [0, 1, 2, 3], label: `Do you experience chronic low back pain that gets worse with fatigue?` },
                { id: 226, options: [0, 1, 2, 3], label: `Become dizzy when standing up suddenly` },
                { id: 227, options: [0, 1, 2, 3], label: `If you go to the chiropractor, do you have difficulty maintaining the correction?` },
                { id: 228, options: [0, 1, 2, 3], label: `Do you have pain after visiting a chiropractor for an adjustment` },
                { id: 229, options: [0, 1, 2, 3], label: `Arthritic tendencies` },
                { id: 230, options: [0, 1, 2, 3], label: `Do you crave salty foods?` },
                { id: 231, options: [0, 1, 2, 3], label: `Do you salt your food before tasting?` },
                { id: 232, options: [0, 1, 2, 3], label: `Do you perspire easily?` },
                { id: 233, options: [0, 1, 2, 3], label: `Do you experience chronic fatigue or experience drowsiness often?` },
                { id: 234, options: [0, 1, 2, 3], label: `Afternoon yawning` },
                { id: 235, options: [0, 1, 2, 3], label: `Afternoon headache` },
                { id: 236, options: [0, 1, 2, 3], label: `Asthma, wheezing or difficulty breathing` },
                { id: 237, options: [0, 1, 2, 3], label: `Tendency to sprain ankles or get "shin splints"` },
                { id: 238, options: [0, 1, 2, 3], label: `Tendency to need sunglasses - meaning that your eyes hurt or are irritated without them` },
                { id: 239, options: [0, 1, 2, 3], label: `Do you have allergies and/or suffer from hives?` },
                { id: 240, options: [0, 1, 2, 3], label: `Do you experience weakness or dizziness?` },
            ]
        },
        {
            section_label: 'Section 10',
            section_name: 'Pituitary',
            symptoms: [
                { id: 241, options: [0, 1], label: `Are you taller than 6' 6" (0 = no, 1 = yes)` },
                { id: 242, options: [0, 1], label: `Did you reach puberty/early sexual development before the age of 10?` },
                { id: 243, options: [0, 1, 2, 3], label: `Increased libido` },
                { id: 244, options: [0, 1, 2, 3], label: `Any types of splitting headaches?` },
                { id: 245, options: [0, 1, 2, 3], label: `Memory failing` },
                { id: 246, options: [0, 1], label: `Do you tolerate sugar, after you eat it you feel fine? (0=no, 1 - yes)` },
                { id: 247, options: [0, 1], label: `Are you shorter than 4' 10" ( 0 = no, 1 = yes)` },
                { id: 248, options: [0, 1, 2, 3], label: `Decreased libido` },
                { id: 249, options: [0, 1, 2, 3], label: `Do you experience excessive thirst?` },
                { id: 250, options: [0, 1, 2, 3], label: `Weight gain around your hips or waist` },
                { id: 251, options: [0, 1, 2, 3], label: `Menstrual disorders` },
                { id: 252, options: [0, 1, 2, 3], label: `Delayed sexual development (after age 13)` },
                { id: 253, options: [0, 1, 2, 3], label: `Tendency to have ulcers or colitis (ulcers in the colon)` },
            ]
        },
        {
            section_label: 'Section 11',
            section_name: 'Thyroid',
            symptoms: [
                { id: 254, options: [0, 1, 2, 3], label: `Are you sensitive or allergic to iodine?` },
                { id: 255, options: [0, 1, 2, 3], label: `Difficulty gaining weight, even with a large appetite?` },
                { id: 256, options: [0, 1, 2, 3], label: `Would you describe yourself as nervous, emotional, can't work under pressure` },
                { id: 257, options: [0, 1, 2, 3], label: `Inward trembling` },
                { id: 258, options: [0, 1, 2, 3], label: `Flush easily, face turns red without embarrassment` },
                { id: 259, options: [0, 1, 2, 3], label: `Fast pulse at rest` },
                { id: 260, options: [0, 1, 2, 3], label: `Do you feel intolerant/uncomfortable to high temperatures` },
                { id: 261, options: [0, 1, 2, 3], label: `Difficulty losing weight?` },
                { id: 262, options: [0, 1, 2, 3], label: `Do you feel mentally sluggish, reduced initiative?` },
                { id: 263, options: [0, 1, 2, 3], label: `Easily fatigued, sleeping during the day` },
                { id: 264, options: [0, 1, 2, 3], label: `Cold hands or feet, sensitive to cold weather, poor circulation` },
                { id: 265, options: [0, 1, 2, 3], label: `Constipation, chronic (you go more than one day without a bowel movement)` },
                { id: 266, options: [0, 1, 2, 3], label: `Excessive hair loss and/or coarse hair` },
                { id: 267, options: [0, 1, 2, 3], label: `Morning headaches that wear off during the day` },
                { id: 268, options: [0, 1, 2, 3], label: `If you look in the mirror, do you notice a loss of the lateral  (outer edge) 1/3 of your eyebrow?` },
                { id: 269, options: [0, 1, 2, 3], label: `Do you experience seasonal sadness with weather changes?` },
            ]
        },
        {
            section_label: 'Section 12',
            section_name: 'Men Only',
            male_only: true,
            skippable: true,
            skip_text: 'MEN ONLY, You may choose to skip this section',
            symptoms: [
                { id: 270, options: [0, 1, 2, 3], label: `Prostate problems?` },
                { id: 271, options: [0, 1, 2, 3], label: `Difficulty with urination, dribbling` },
                { id: 272, options: [0, 1, 2, 3], label: `Difficult to stop and start urine stream` },
                { id: 273, options: [0, 1, 2, 3], label: `Pain or burning with urination` },
                { id: 274, options: [0, 1, 2, 3], label: `Walking to urinate at night` },
                { id: 275, options: [0, 1, 2, 3], label: `Interruption of stream during urination` },
                { id: 276, options: [0, 1, 2, 3], label: `Do you notice pain if you touch the inside of your legs or heals` },
                { id: 277, options: [0, 1, 2, 3], label: `Feeling of incomplete bowel evacuation` },
                { id: 278, options: [0, 1, 2, 3], label: `Decreased sexual function` },
            ]
        },
        {
            section_label: 'Section 13',
            section_name: 'Women Only',
            female_only: true,
            skippable: true,
            skip_text: 'WOMEN ONLY, You may choose to skip this section',
            symptoms: [
                { id: 279, options: [0, 1, 2, 3], label: `Do you experience depression during your periods` },
                { id: 280, options: [0, 1, 2, 3], label: `Mood swings associated with PMS/PMDD` },
                { id: 281, options: [0, 1, 2, 3], label: `Cave chocolate around your periods` },
                { id: 282, options: [0, 1, 2, 3], label: `Do you experience breast tenderness around your periods` },
                { id: 283, options: [0, 1, 2, 3], label: `Excessive menstrual blood flow` },
                { id: 284, options: [0, 1, 2, 3], label: `Scanty blood flow during periods` },
                { id: 285, options: [0, 1, 2, 3], label: `Occasional skipped periods` },
                { id: 286, options: [0, 1, 2, 3], label: `Variations in menstrual cycle, under 28 days or over 32 days` },
                { id: 287, options: [0, 1, 2, 3], label: `Have you been diagnosed with Endometriosis` },
                { id: 288, options: [0, 1, 2, 3], label: `Uterine fibroids` },
                { id: 289, options: [0, 1, 2, 3], label: `Have you been told you have breast fibroids, benign masses, dense breasts` },
                { id: 290, options: [0, 1, 2, 3], label: `Do you experience painful intercourse (dyspareunia)` },
                { id: 291, options: [0, 1, 2, 3], label: `Any vagional discharge not associated with ovulation?` },
                { id: 292, options: [0, 1, 2, 3], label: `Vagional dryness` },
                { id: 293, options: [0, 1, 2, 3], label: `Vagional itchiness` },
                { id: 294, options: [0, 1, 2, 3], label: `Are you noticing that you are gaining weight around your hips, thighs, buttocks?` },
                { id: 295, options: [0, 1, 2, 3], label: `Any excessive facial hair or body hair?` },
                { id: 296, options: [0, 1, 2, 3], label: `Are you experiencing hot flashes?` },
                { id: 297, options: [0, 1, 2, 3], label: `Night sweats associated with menopause` },
                { id: 298, options: [0, 1, 2, 3], label: `Thinning skin? Are you seeing more noticeable veins in your hands?` },
            ]
        },
        {
            section_label: 'Section 14',
            supportive_message: 'WHO KNEW ALL OF THESE QUESTIONS WERE SYMPTOMS?  THESE ARE THE LAST 3 SECTIONS AND THEY ARE SMALL. :) YOUR REPORT IS ALMOST COMPLETED. KEEP GOING!',
            section_name: 'Cardiovascular',
            symptoms: [
                { id: 299, options: [0, 1, 2, 3], label: `Are you aware of any heavy/irregular breathing` },
                { id: 300, options: [0, 1, 2, 3], label: `Discomfort at high altitudes` },
                { id: 301, options: [0, 1, 2, 3], label: `"Air hunger", breath in quickly, deeply out of the blue or sigh frequently` },
                { id: 302, options: [0, 1, 2, 3], label: `Are you compelled to open the windows in a closed room?` },
                { id: 303, options: [0, 1, 2, 3], label: `Do you notice that you experience shortness of breath with moderate exercise?` },
                { id: 304, options: [0, 1, 2, 3], label: `Do your ankles swell, especially at the end of the day?` },
                { id: 305, options: [0, 1, 2, 3], label: `Do you cough at night?` },
                { id: 306, options: [0, 1, 2, 3], label: `Blush or your face turns red for no reason` },
                { id: 307, options: [0, 1, 2, 3], label: `Do you experience any dull pain or tightness in chest and/or radiates into your right arm, gets worse with exertion` },
                { id: 308, options: [0, 1, 2, 3], label: `Any muscle cramps with exertion, leg squats and cramping, climbing stairs and cramping?` },

            ]
        },
        {
            section_label: 'Section 15',
            section_name: 'Kidney & Bladder',
            symptoms: [
                { id: 309, options: [0, 1, 2, 3], label: `Do you experience any pain in your mid-back region?` },
                { id: 310, options: [0, 1, 2, 3], label: `Puffy eyes, dark circles under your eyes` },
                { id: 311, options: [0, 1], label: `Any history of kidney stones? (0 = no, 1 = yes)` },
                { id: 312, options: [0, 1, 2, 3], label: `Do you notice any cloudy, bloody or darkened urine?` },
                { id: 313, options: [0, 1, 2, 3], label: `Does your urine have a strong odor?` },
            ]
        },
        {
            section_label: 'Section 16',
            section_name: 'Immune System',
            symptoms: [
                { id: 314, options: [0, 1, 2, 3], label: `Runny or drippy nose` },
                { id: 315, options: [0, 1, 2, 3], label: `Catch colds at the beginning of the winter` },
                { id: 316, options: [0, 1, 2, 3], label: `Do you have a mucus producing cough now?` },
                { id: 317, options: [0, 1, 2, 3], label: `Do you catch frequent colds? ( 0 = 1 or less cold, per year. 1 = 2 to 3 colds, per year, 2 = 4 to 5 colds, per year, 3 = 6 or more colds, per year)` },
                { id: 318, options: [0, 1, 2, 3], label: `Do you experience other infections? (sinus, ear, lung, skin, bladder, kidney, etc.) ( 0 = 1 or less per year. 1 = 2 to 3 per year, 2 = 4 to 5 per year, 3 = 6 or more infections per year)` },
                { id: 319, options: [0, 1, 2, 3], label: `Would you say, you "never get sick" ( 0= sick only 1 or 2 times in last 2yrs. 1 = not sick in last 2 years, 2 = not sick in last 4 years, 3 = not sick in last 7 years)` },
                { id: 320, options: [0, 1, 2, 3], label: `Adult acne?` },
                { id: 321, options: [0, 1, 2, 3], label: `Itchy skin (dermatitis)` },
                { id: 322, options: [0, 1, 2, 3], label: `Any cysts, boils, rashes` },
                { id: 323, options: [0, 1, 2, 3], label: `History of Epstein Barr, Mono, Herpes, Shingles, Chronic Fatigue Syndrome, Hepatitis or other chronic viral condition ( 0 = no, 1 = yes in the past, 2 = currently have/mild condition, 3 = currently have/severe condition)` },
            ]
        },
    ]
}

export const AnalysisData: {
    section_name: string,
    low_priority: {
        start: number, end: number
    },
    medium_priority: {
        start: number, end: number
    },
    high_priority: {
        start: number, end: number
    },
    highest_score: number
}[] = [
        {
            section_name: 'Upper G.I.',
            low_priority: {
                start: 0,
                end: 9
            },
            medium_priority: {
                start: 9,
                end: 14
            },
            high_priority: {
                start: 14,
                end: 1000
            },
            highest_score: 19
        },
        {
            section_name: 'Liver Gallbladder',
            low_priority: {
                start: 0,
                end: 11
            },
            medium_priority: {
                start: 11,
                end: 17
            },
            high_priority: {
                start: 17,
                end: 1000
            },
            highest_score: 22
        },
        {
            section_name: 'Small Intestine',
            low_priority: {
                start: 0,
                end: 7
            },
            medium_priority: {
                start: 7,
                end: 12
            },
            high_priority: {
                start: 12,
                end: 1000
            },
            highest_score: 16
        },
        {
            section_name: 'Large Intestine',
            low_priority: {
                start: 0,
                end: 9
            },
            medium_priority: {
                start: 9,
                end: 15
            },
            high_priority: {
                start: 15,
                end: 1000
            },
            highest_score: 20
        },
        {
            section_name: 'Mineral Needs',
            low_priority: {
                start: 0,
                end: 12
            },
            medium_priority: {
                start: 12,
                end: 19
            },
            high_priority: {
                start: 19,
                end: 1000
            },
            highest_score: 25
        },
        {
            section_name: 'Essential Fatty Acids',
            low_priority: {
                start: 0,
                end: 3
            },
            medium_priority: {
                start: 3,
                end: 5
            },
            high_priority: {
                start: 5,
                end: 1000
            },
            highest_score: 8
        },
        {
            section_name: 'Sugar Handling',
            low_priority: {
                start: 0,
                end: 6
            },
            medium_priority: {
                start: 6,
                end: 10
            },
            high_priority: {
                start: 10,
                end: 1000
            },
            highest_score: 14
        },
        {
            section_name: 'Vitamin Needs',
            low_priority: {
                start: 0,
                end: 12
            },
            medium_priority: {
                start: 12,
                end: 20
            },
            high_priority: {
                start: 20,
                end: 1000
            },
            highest_score: 28
        },
        {
            section_name: 'Adrenal',
            low_priority: {
                start: 0,
                end: 12
            },
            medium_priority: {
                start: 12,
                end: 20
            },
            high_priority: {
                start: 20,
                end: 1000
            },
            highest_score: 28
        },
        {
            section_name: 'Pituitary',
            low_priority: {
                start: 0,
                end: 4
            },
            medium_priority: {
                start: 4,
                end: 8
            },
            high_priority: {
                start: 8,
                end: 1000
            },
            highest_score: 10
        },
        {
            section_name: 'Thyroid',
            low_priority: {
                start: 0,
                end: 7
            },
            medium_priority: {
                start: 7,
                end: 12
            },
            high_priority: {
                start: 12,
                end: 1000
            },
            highest_score: 17
        },
        {
            section_name: 'Men Only',
            low_priority: {
                start: 0,
                end: 5
            },
            medium_priority: {
                start: 5,
                end: 7
            },
            high_priority: {
                start: 7,
                end: 1000
            },
            highest_score: 10
        },
        {
            section_name: 'Women Only',
            low_priority: {
                start: 0,
                end: 9
            },
            medium_priority: {
                start: 9,
                end: 15
            },
            high_priority: {
                start: 15,
                end: 1000
            },
            highest_score: 21
        },
        {
            section_name: 'Cardiovascular',
            low_priority: {
                start: 0,
                end: 4
            },
            medium_priority: {
                start: 4,
                end: 8
            },
            high_priority: {
                start: 8,
                end: 1000
            },
            highest_score: 11
        },
        {
            section_name: 'Kidney & Bladder',
            low_priority: {
                start: 0,
                end: 3
            },
            medium_priority: {
                start: 3,
                end: 4
            },
            high_priority: {
                start: 4,
                end: 1000
            },
            highest_score: 5
        },
        {
            section_name: 'Immune System',
            low_priority: {
                start: 0,
                end: 4
            },
            medium_priority: {
                start: 4,
                end: 8
            },
            high_priority: {
                start: 8,
                end: 1000
            },
            highest_score: 11
        },
    ]


































import { createSlice } from '@reduxjs/toolkit';
import { ProfileType } from 'model/symptom';

interface FormDataType {
  SIGNUP: any,
  QUESTIONNAIRE: any,
  SIGNUP_THEME_TITLES: any,
  // company search txt
  company_search_txt: any,
  // survey data
  PROFILE: ProfileType | null,
  SURVEY_PROGRESS: number,
  SYMPTOM_ANSWERS: {
    id: number,
    value: number
  }[]
}
const initialState: FormDataType = {
  // signup form data
  SIGNUP: 0,
  QUESTIONNAIRE: null,
  SIGNUP_THEME_TITLES: [],
  // company search txt
  company_search_txt: '',
  // survey data
  PROFILE: null,
  SURVEY_PROGRESS: 0,
  SYMPTOM_ANSWERS: []
}

export const slice = createSlice({
  name: 'formdata',
  initialState,
  reducers: {
    saveSignup: (state, { payload: data }) => {
      return { ...state, SIGNUP: data }
    },
    saveSignupThemeTitles: (state, { payload: data }) => {
      return { ...state, SIGNUP_THEME_TITLES: data }
    },
    saveQuestionnaire: (state, { payload: data }) => {
      return { ...state, QUESTIONNAIRE: data }
    },
    saveProfile: (state, { payload: data }) => {
      return { ...state, PROFILE: data }
    },
    saveCompanySearchTxt: (state, { payload: data }) => {
      return { ...state, company_search_txt: data }
    },
    updateSurveyProgress: (state, { payload: data }) => {
      return { ...state, SURVEY_PROGRESS: data }
    },
    updateSymptomAnswers: (state, { payload: data }) => {
      const new_symtoms = state.SYMPTOM_ANSWERS.filter(s => s.id != data.id)
      new_symtoms.push(data)
      return {
        ...state,
        SYMPTOM_ANSWERS: new_symtoms
      }
    },
    initSurveyData: (state) => {
      return {
        ...state,
        SYMPTOM_ANSWERS: [],
        PROFILE: null
      }
    },
  },
});
// Exports
export const {
  saveSignup,
  saveSignupThemeTitles,
  saveQuestionnaire,
  saveProfile,
  saveCompanySearchTxt,
  updateSurveyProgress,
  updateSymptomAnswers,
  initSurveyData
} = slice.actions


export default slice.reducer;
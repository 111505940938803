import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { CallApi } from 'utils/Request';

export default function () {
  const [testComplete, setTestComplete] = useState(false)
  const [apiWorking, setApiWorking] = useState(false)
  useEffect(() => {
    CallApi({
      url: '/test',
    }).then(() => {
      console.info('=== api working ===')
      setApiWorking(true)
    }).catch((e) => {
      console.error('=== api not working!!! ===')
      console.info(e)
    }).finally(() => {
      setTestComplete(true)
    })
  }, [])

  return <Box>
    {
      testComplete && <Box>
        {apiWorking && <Typography color='success'>Api works</Typography>}
        {!apiWorking && <Typography color='error'>Api Doesn't works</Typography>}
      </Box>
    }
    {
      !testComplete && <Box>
        {<Typography>Testing...</Typography>}
      </Box>
    }
  </Box>
}
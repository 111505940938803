import { Box, Container, Typography, Button, Grid } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import Section from 'components/Section';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import { updateSurveyProgress } from 'redux/reducers/formdata';
import { useDispatch, useSelector } from 'react-redux';
import { Part1Data, Part2Data } from 'config/constants'
import { RootState } from 'redux/store';
import SupportiveModal from 'components/SupportiveModal';
import AssessCompleteModal from 'components/AssessCompleteModal';
import { CallApi } from 'utils/Request';
import { initPaymentData } from 'redux/reducers/appdata';

const TOTAL_SECTIONS = Part1Data.sections.length + Part2Data.sections.length
export default function () {
    let [searchParams, setSearchParams] = useSearchParams();

    const [activeSectionIndex, setActiveSectionIndex] = useState(0)
    const [showSupportiveModal, setShowSupportiveModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const formdata = useSelector((state: RootState) => state.formdata)
    const appdata = useSelector((state: RootState) => state.appdata)
    const gender = formdata.PROFILE?.birth_gender
    const symptom_answers = formdata.SYMPTOM_ANSWERS
    const activeSection = Part2Data.sections[activeSectionIndex]

    const onNext = () => {

        handleNext()
    }
    const handleNext = () => {

        const isLast = Part2Data.sections.length == activeSectionIndex + 1
        if (isLast) {
            setShowSuccessModal(true)
            dispatch(updateSurveyProgress(100))

        } else {
            const newIndex = activeSectionIndex + 1
            navigate('?index=' + newIndex)
        }
    }
    useEffect(() => {
        const index = searchParams.get('index')
        if (index) {
            setActiveSectionIndex(Number(index))
        }
    }, [searchParams])
    useEffect(() => {
        if (showSuccessModal) {
            const postdata = {
                ...formdata.PROFILE,
                symptom_answers: formdata.SYMPTOM_ANSWERS,
                checkout_session_id: appdata.CHECKOUT_SESSION_ID
            }
            CallApi({
                url: 'survey/create',
                data: postdata,
                method: 'post'
            }).then((res: any) => {
                dispatch(initPaymentData(null))
                console.info('=== res.data ===', JSON.stringify(res.data, null, 4))
            })
        }
    }, [showSuccessModal])
    useEffect(() => {
        window.scrollTo(0, 0)

        const progress = (Part1Data.sections.length + activeSectionIndex) * 100 / TOTAL_SECTIONS
        dispatch(updateSurveyProgress(progress))
        if (gender == 'male' && activeSection.female_only == true) {
            onNext()
        }
        if (gender == 'female' && activeSection.male_only == true) {
            onNext()
        }


        if (activeSection.supportive_message) {
            setShowSupportiveModal(true)
        } else {
            setShowSupportiveModal(false)
        }
    }, [activeSectionIndex])
    const onCompleteSurvey = () => {
        navigate('/survey/result')
    }
    const answeredAllSymptomsInSection = useMemo(() => {
        const all_symptomIds = Part2Data.sections[activeSectionIndex].symptoms.map(s => s.id)
        for (let index = 0; index < all_symptomIds.length; index++) {
            const element = all_symptomIds[index];
            if (symptom_answers.findIndex(sa => sa.id == element) < 0) {
                return false
            }
        }
        return true
    }, [symptom_answers])
    return <Box>
        <Container maxWidth='xl' sx={{ minHeight: '90vh' }}>

            <Box my={6}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Box my={6}>
                            <Typography mt={4} color='info.main' variant='h6'>{Part2Data.description}</Typography>
                            <Box my={4}>
                                {
                                    Part2Data.options.map((option, key) => {
                                        return (
                                            <Typography mr={2} variant='h6' key={key}
                                                mb={2}
                                            >{option.value} = {option.label}, </Typography>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box>
                            {
                                Part2Data.sections.map((section, key) => {
                                    if (activeSectionIndex == key) {
                                        return <Box key={key}>
                                            <Section section={section} onSkipSection={onNext} />
                                        </Box>
                                    } else {
                                        return null
                                    }

                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box my={4} textAlign="right">
                <Button variant="contained"
                    onClick={onNext}
                    disabled={!answeredAllSymptomsInSection}
                >
                    {Part2Data.sections.length == (activeSectionIndex + 1) ? 'Finish' : 'Next'}
                    <NavigateNextIcon />
                </Button>
            </Box>
            {/* <SupportiveModal message={
            `Fantastic!  You are doing great. Let's move on now to look at your body's systems and organs and check in. Answer using the key and be thoughtful of the last 90 days.`
        } onConfirm={() => setShowSupportiveModal(false)} /> */}

        </Container>
        {
            showSuccessModal && <AssessCompleteModal
                onConfirm={() => onCompleteSurvey()} />
        }
        <SupportiveModal
            title={activeSection.supportive_title}
            message={activeSection.supportive_message}
            onConfirm={() => {
                setShowSupportiveModal(false)
                setTimeout(() => {
                    window.scrollTo(0, 0)
                }, 0);
            }}
            open={showSupportiveModal}
        />
    </Box>
}
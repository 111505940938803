import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment, { Moment } from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GenderType, ProfileType } from '../../model/symptom';
import { useDispatch, useSelector } from 'react-redux';
import { initSurveyData, saveProfile, updateSurveyProgress } from '../../redux/reducers/formdata';
import { useNavigate, useNavigation } from 'react-router-dom';
import { RootState } from 'redux/store';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import validator from 'validator'

export default function () {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const formdata = useSelector((state: RootState) => state.formdata)
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    // formdata
    const [name, setName] = useState(formdata.PROFILE?.name || '')
    const [email, setEmail] = useState(formdata.PROFILE?.email || '')
    const [birthdate, setBirthdate] = useState<Moment>(moment(formdata.PROFILE?.birth_date || Date.now()))
    const [gender, setGender] = useState<GenderType>(formdata.PROFILE?.birth_gender || 'male')
    const [health_concerns, setHealth_concerns] = useState(formdata.PROFILE?.health_concerns || ['', '', '', ''])

    useEffect(() => {
        dispatch(initSurveyData())
        dispatch(updateSurveyProgress(0))
    }, [])

    const onSubmit = () => {
        if (name.trim().length < 1) {
            setErrorMsg('Please enter name')
            return
        }
        if (!validator.isEmail(email)) {
            setErrorMsg('Please enter email')
            return
        }
        const profile: ProfileType = {
            name: name,
            email: email,
            birth_date: birthdate.format('l'),
            birth_gender: gender,
            health_concerns: health_concerns,
            submit_date: moment().format('l')
        }
        dispatch(saveProfile(profile))
        navigate('/survey/part1')
    }
    return <Box>
        {errorMsg && <Alert severity="error" sx={{ position: 'fixed', left: 0, top: 0, right: 0}}>{errorMsg}</Alert>}

        <Box my={6}>
            <Typography variant='h4' sx={{ color: 'info.main', textAlign: 'center' }}>
                SexyStrongLife Optimal Health Assessment
            </Typography>
        </Box>
        <Container maxWidth='sm'>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box mb={4}>
                    <Typography variant='body1' textAlign="right" fontStyle="italic">Date: {moment().format('L')}</Typography>
                </Box>
                <Box mb={4}>
                    <TextField variant='filled' label="Name:" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
                </Box>
                <Box mb={4}>
                    <TextField variant='filled' label="Email:" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
                </Box>
                <Box mb={4}>
                    <MobileDatePicker
                        label="Birth Date:"
                        inputFormat="MM/DD/YYYY"
                        value={birthdate}
                        onChange={(val) => {
                            if (val) {
                                setBirthdate(val)
                            }
                        }}
                        renderInput={(params) => <TextField {...params} variant='filled'
                            fullWidth
                        />}
                    />
                </Box>
                <Box mb={4}>
                    <Typography variant='body1' fontStyle="italic">Birth Gender:</Typography>
                    <Box>
                        <FormControlLabel control={<Checkbox />} label="Male" checked={gender == 'male'} onChange={() => setGender('male')}></FormControlLabel>
                        <FormControlLabel control={<Checkbox />} label="Female" checked={gender == 'female'} onChange={() => setGender('female')}></FormControlLabel>
                    </Box>
                </Box>
                <Box mb={1}>
                    <Typography variant='body1' fontStyle="italic">
                        Please share your top 4 health concerns in order of importance.
                    </Typography>
                </Box>
                {
                    health_concerns.map((hc, key) => {
                        return <Box mb={1} key={key}>
                            <TextField variant='filled' label={key + 1} fullWidth value={hc} onChange={(e) => {
                                const newValue = [...health_concerns]
                                newValue[key] = e.target.value
                                setHealth_concerns(newValue)
                            }} />
                        </Box>
                    })
                }
            </LocalizationProvider>
            <Box my={6}>
                <Button variant='contained' fullWidth onClick={onSubmit}>Next</Button>
            </Box>
        </Container>
    </Box>

}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {

    IS_LOGGED_IN: false,
    TOKEN: null,
    REMEMBER_ME: false,
    PROFILE: null,
    SELECTED_ROLE: null,
    EXPIRES_ON: 0,

    EMAIL: '',
    PASSWORD: '',
}


export const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, { payload: data }) => {
            const roles = data.roles
            let role = null
            if (roles && roles.length > 0) {
                role = roles[0]
            }
            return {
                ...state,
                IS_LOGGED_IN: true,
                TOKEN: data.access,
                PROFILE: data.user,
                EXPIRES_ON: data.expires_on,
                SELECTED_ROLE: role,
                REMEMBER_ME: data.remember_me
            }
        },

        saveCredential: (state, { payload: data }) => {
            return { ...state, EMAIL: data.email, PASSWORD: data.password }
        },
        changeRole: (state, { payload: data }) => {
            return { ...state, SELECTED_ROLE: data.selectedRole }
        },
        refreshToken: (state, { payload: data }) => {
            return { ...state, TOKEN: data.access, EXPIRES_ON: data.expires_on }
        },
        logout: (state, { payload: data }) => {
            return {
                ...state,
                IS_LOGGED_IN: false,
                REMEMBER_ME: false,
                TOKEN: null
            }
        },
        rememberme: (state, { payload: data }) => {
            return { ...state, REMEMBER_ME: data }
        },
        updateProfile: (state, { payload: data }) => {
            return {
                ...state,
                PROFILE: {
                    ...state.PROFILE,
                    ...data
                }
            }
        },
    },
});
// Exports
export const {
    login,
    saveCredential,
    changeRole,
    refreshToken,
    logout,
    rememberme,
    updateProfile,
} = slice.actions


export default slice.reducer;
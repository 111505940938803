import { Box, Typography } from '@mui/material';
import LinearProgressWithLabel from 'components/LinearProgressWithLabel';
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Part1Data, Part2Data } from 'config/constants'
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const TOTAL_SECTIONS = Part1Data.sections.length + Part2Data.sections.length

function Footer() {
    return <Box sx={{
        backgroundColor: 'info.dark',
        px: 2,
        py: 1
    }}>
        <Box display='flex' justifyContent={'space-between'}>
            <Box>
                <Typography color='white'>SHERI@SEXYSTRONGLIFE.COM</Typography>
                <Typography color='white'>(818) 516-0111</Typography>
            </Box>
            <Box>
                <Typography color='white'>ALL RIGHTS. RESERVED</Typography>
            </Box>
        </Box>
    </Box>
}
export default function () {
    const location = useLocation()
    const formdata = useSelector((state: RootState) => state.formdata)

    return <Box>
        <Outlet />
        <Footer />
    </Box>
}
import { default as React, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Container } from '@mui/system';
import { Typography } from '@mui/material';
import { SnapshotPDF } from './SnapshotPdf';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import { getPart2Score, ScoreType } from 'utils/SurveyUtil';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { ProfileType, SurveyResultType } from 'model/symptom';
import { CallApi } from 'utils/Request';

const CustomizedPDFDownloadLink = styled(PDFDownloadLink)`
    background-color: #20b2aa;
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-size: 24px;
    text-decoration: none;
    :hover {
        background-color: #0082aa;
    }
`;
export default function () {
    const [surveyData, setSurveyData] = useState<SurveyResultType | null>(null)

    const symptomAnswers = surveyData?.symptom_answers || []
    const profile: ProfileType = surveyData as ProfileType
    const params = useParams()

    useEffect(() => {
        if (params.checkout_session_id) {
            CallApi({
                url: 'survey/get/' + params.checkout_session_id,
                method: 'get'
            }).then((res) => {
                setSurveyData(res)
            })
        }
    }, [params])
    if (!surveyData) {
        return null
    }
    return (
        <Container maxWidth='lg'>
            <Box mt={4}>
                <Typography variant='h2' color='primary.main'>{profile?.name}'s Assessment</Typography>
            </Box>
            <Box my={2} textAlign='right'>
                <CustomizedPDFDownloadLink document={<SnapshotPDF symptomAnswers={symptomAnswers} profile={profile} />}
                    fileName={`${profile?.name}-snapshot.pdf`}>
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download now!'
                    }
                </CustomizedPDFDownloadLink>
            </Box>
            <Box pt={4}>
                <PDFViewer style={{ width: '100%', height: 1240 }}>
                    <SnapshotPDF symptomAnswers={symptomAnswers} profile={profile} />
                </PDFViewer>
            </Box>
        </Container>
    );
}

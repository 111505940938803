import { createSlice } from '@reduxjs/toolkit';
// Initial State
const initialState = {
  PAYMENT_COMPLETED: false,
  CHECKOUT_SESSION_ID: ''
};

export const slice = createSlice({
  name: 'appdata',
  initialState,
  reducers: {

    paymentCompleted: (state, { payload: data }) => {
      return {
        ...state,
        PAYMENT_COMPLETED: true,
        CHECKOUT_SESSION_ID: data
      }
    },
    initPaymentData: (state, { payload: data }) => {
      return {
        ...state,
        PAYMENT_COMPLETED: false,
        CHECKOUT_SESSION_ID: undefined
      }
    },
  }

})
// Exports
export const {
  paymentCompleted,
  initPaymentData
} = slice.actions;


export default slice.reducer;